import axios, { AxiosInstance } from "axios";
const prodKey =
  "pk_live_51P2g1S2LkYsHifHTSEdSmGcGblYaYCiW3E6zz1uTdwDWmSrPamFeXiTolcDREPlluKjsoN9R04fbEJ9RY3OgTi0S00ZGDCzSHw";
const testKey =
  "pk_test_51PEEayKXY2rQq7J5RxNt5fEflYKPlZ9NwkpBKdaSVmOTnoerHrBcw1RpiXucspudpzai0bEGe2S1IAao6iS0y08G00mNRRuyUR";

export const envURL = "https://haltek.trackwayz.com/api/";

export const prodURL = "https://wewillremember.com/api/";

export const stripeKey =
  process.env.NODE_ENV === "development" ? testKey : testKey;

export const baseUrl: AxiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? envURL : envURL
});

//Request
baseUrl.interceptors.request.use(
  function (config: any) {
    return config;
  },
  function (err: any) {
    return Promise.reject(err);
  }
);

// Response
baseUrl.interceptors.response.use(
  function (response: any) {
    if (response.data.responseObject) {
      return response.data.responseObject;
    } else {
      return response.data;
    }
  },
  function (error: any) {
    return error;
  }
);
